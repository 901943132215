import { NavItem } from "@definitions/types"

export const NavItems: Array<NavItem> = [
  {
    label: "why_emenu",
    href: "/why-emenu",
  },
  {
    label: "features",
    href: "/features",
  },
  {
    label: "pricing",
    href: "/pricing",
  },
  {
    label: "about",
    href: "/about",
  },
  {
    label: "blog",
    href: "/blog",
  },
  {
    label: "contact",
    href: "/contact",
  },
]

export const SocialLinks: Array<NavItem> = [
  {
    label: "instagram",
    href: "https://www.instagram.com/digitalemenu",
  },
  {
    label: "facebook",
    href: "https://www.facebook.com/digitalemenu",
  },
  {
    label: "linkedin",
    href: "https://www.linkedin.com/showcase/digitalemenu/",
  },
]

export const OtherLinks: Array<NavItem> = [
  {
    label: "terms",
    href: "/terms",
  },
  {
    label: "privacy",
    href: "/privacy",
  },
  {
    label: "refunds",
    href: "/refunds",
  },
]
