import { Link } from "@chakra-ui/react"
import { NextLinkProps } from "@definitions/types"
import NextLink from "next/link"
import { useRouter } from "next/router"

export default function MenuLink({ href, label, ...rest }: NextLinkProps) {
  const linkColor = "brand.black"
  const linkHoverColor = "white"
  const { pathname } = useRouter()
  return (
    <Link
      as={NextLink}
      href={href}
      px={4}
      py={1}
      fontSize={"2xl"}
      fontWeight={700}
      color={pathname === href ? linkHoverColor : linkColor}
      textTransform={"uppercase"}
      transition={`all 0.3s ease-in-out`}
      borderColor={"transparent"}
      borderRadius={4}
      backgroundColor={pathname === href ? "brand.dark" : "transparent"}
      aria-label={label}
      _hover={{
        color: linkHoverColor,
        textDecoration: "none",
        backgroundColor: "brand.secondary",
      }}
      {...rest}
    >
      {label}
    </Link>
  )
}
