import { Box } from "@chakra-ui/react"
import { GuaranteeProps } from "@definitions/types"
import guarantee_arabic from "@public/lottie/guarantee_arabic.json"
import guarantee_english from "@public/lottie/guarantee_english.json"
import { useRouter } from "next/router"
import Lottie from "react-lottie-player/dist/LottiePlayerLight"

export default function Guarantee(props: GuaranteeProps) {
  const { loop, play, ...rest } = props
  const { locale } = useRouter()

  return (
    <Box {...rest}>
      <Lottie
        animationData={locale === "ar" ? guarantee_arabic : guarantee_english}
        loop={loop}
        play={play}
      />
    </Box>
  )
}
