import { Flex } from "@chakra-ui/react"
import HeaderNav from "@components/nav/HeaderNav"
import { HeaderLayoutProps } from "@definitions/types"

export default function HeaderLayout({
  fullScreen,
  landingPage,
  ...props
}: HeaderLayoutProps) {
  return (
    <Flex
      direction={"column"}
      height={fullScreen ? "100vh" : "auto"}
      position={"relative"}
      {...props}
    >
      <HeaderNav
        landingPage={landingPage}
        backgroundColor={fullScreen ? "brand.alpha" : "brand.primary"}
      />
      {props.children}
    </Flex>
  )
}
