import { Button } from "@chakra-ui/react"
import { HoverMenuProps } from "@definitions/types"
import { useTranslations } from "next-intl"
import { useEffect, useState } from "react"
import { useWindowScroll } from "react-use"

export default function HoverMenu({ onOpen, ...rest }: HoverMenuProps) {
  const { y: windowY } = useWindowScroll()
  const [displayMenu, setDisplayMenu] = useState<number>(0)

  useEffect(() => {
    if (windowY > 100) {
      setDisplayMenu(1)
    } else {
      setDisplayMenu(0)
    }
  }, [windowY])

  const t = useTranslations("nav")

  return (
    <Button
      variant={"link"}
      onClick={onOpen}
      color={"brand.primary"}
      backgroundColor={"whitesmoke"}
      borderRadius={"full"}
      width={120}
      height={120}
      position={"fixed"}
      right={10}
      opacity={displayMenu}
      textTransform={"uppercase"}
      transition={`all 0.3s ease-in-out`}
      shadow={"xl"}
      _hover={{
        textDecoration: "none",
        backgroundColor: "brand.secondary",
        color: "white",
      }}
      {...rest}
    >
      {t("menu")}
    </Button>
  )
}
