import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  useDisclosure,
} from "@chakra-ui/react"
import HoverMenu from "@components/nav/HoverMenu"
import LanguageSelect from "@components/nav/LanguageSelect"
import MenuDrawer from "@components/nav/MenuDrawer"
import { NavItems } from "@data/nav"
import { HeaderNavProps } from "@definitions/types"
import SvgLogo from "@public/svg/logo.svg"
import SvgMenuIcon from "@public/svg/misc/menu-icon.svg"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { useTranslations } from "next-intl"
import { useEffect, useState } from "react"
import { useWindowScroll } from "react-use"

const HeaderNav = ({ landingPage, ...props }: HeaderNavProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const linkColor = "white"
  const linkHoverColor = "brand.light"
  const { pathname, locale } = useRouter()
  const { y: windowY } = useWindowScroll()
  const [hoverMenuIcon, setHoverMenuIcon] = useState<boolean>(false)
  const t = useTranslations("nav")

  useEffect(() => {
    if (windowY > 100) {
      setHoverMenuIcon(true)
    } else {
      setHoverMenuIcon(false)
    }
  }, [windowY])

  return (
    <Box
      position={"relative"}
      backgroundColor={"brand.primary"}
      shadow={"md"}
      py={6}
      zIndex={1}
      {...props}
    >
      <Container
        maxW={"container.xl"}
        position={"relative"}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Link
            as={NextLink}
            href={"/"}
            _focus={{ boxShadow: "none" }}
            aria-label={`Digital eMenu`}
            zIndex={1}
          >
            <Icon
              as={SvgLogo}
              color={"white"}
              width={{ base: 40, md: 56 }}
              height={24}
            />
          </Link>

          <Box display={{ base: "none", md: "block" }}>
            <HStack spacing={{ base: 4, xl: 8 }}>
              {!landingPage &&
                NavItems.map((navItem, index) => (
                  <Box key={index}>
                    <Link
                      as={NextLink}
                      href={navItem.href ?? "#"}
                      fontWeight={500}
                      fontSize={{ base: "xs", lg: "md" }}
                      color={
                        pathname === navItem.href ? linkHoverColor : linkColor
                      }
                      borderBottomWidth={
                        pathname === navItem.href ? "2px" : "none"
                      }
                      borderColor={linkHoverColor}
                      borderStyle={"dotted"}
                      letterSpacing={1}
                      _hover={{
                        textDecoration: "none",
                        color: linkHoverColor,
                      }}
                    >
                      {t(navItem.label)}
                    </Link>
                  </Box>
                ))}
              <LanguageSelect fontWeight={500} />
              {!landingPage && (
                <Button
                  as={NextLink}
                  href={`/contact`}
                  fontSize={"sm"}
                  textTransform={"uppercase"}
                  display={{ base: "none", xl: "flex" }}
                  shadow={"md"}
                  bg={"white"}
                  color={"brand.dark"}
                >
                  {t("demo_button_text")}
                </Button>
              )}
            </HStack>
            {!landingPage && (
              <HoverMenu
                onOpen={onOpen}
                zIndex={1}
              />
            )}
          </Box>
          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            display={{ base: "flex", md: "none" }}
          >
            <LanguageSelect
              backgroundColor={"brand.dark"}
              shadow={"sm"}
              me={4}
            />
            {!landingPage && (
              <IconButton
                borderRadius={"full"}
                backgroundColor={"brand.dark"}
                position={hoverMenuIcon ? "fixed" : "relative"}
                onClick={onOpen}
                color={"white"}
                w={14}
                h={14}
                p={2}
                transition={"all 0.3s"}
                transform={locale === "ar" ? "scaleX(-1)" : "scaleX(1)"}
                icon={<SvgMenuIcon />}
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
                zIndex={1}
                _hover={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent" }}
              />
            )}
            <MenuDrawer
              isOpen={isOpen}
              onClose={onClose}
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default HeaderNav
