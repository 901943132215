import { Select, SelectProps } from "@chakra-ui/react"
import { titleCase } from "@utils/utils"
import { useRouter } from "next/router"
import { ChangeEvent } from "react"

const LanguageSelect = (props: SelectProps) => {
  const router = useRouter()
  const { pathname, push, asPath, query, locale, locales } = router

  const onChangeHandler = async (e: ChangeEvent<HTMLSelectElement>) => {
    document.cookie = `NEXT_LOCALE=${e.target.value}; max-age=31536000; path=/`
    await push({ pathname, query }, asPath, { locale: e.target.value })
  }

  return (
    <Select
      bg={"brand.dark"}
      color={"white"}
      variant={"filled"}
      w={20}
      value={locale}
      onChange={onChangeHandler}
      borderRadius={"md"}
      shadow={"md"}
      _hover={{ cursor: "pointer", bg: "brand.dark" }}
      {...props}
    >
      {locales
        ?.filter((l) => l !== "default")
        .map((locale) => (
          <option
            key={locale}
            value={locale}
          >
            {titleCase(locale)}
          </option>
        ))}
    </Select>
  )
}

export default LanguageSelect
